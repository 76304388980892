/*

STYLE USED BY THE VECTORIAL ANIMATIONS OF THE WILD-ANIMATION LIBRARY

*/



.operator {

    transition-property: all;
    transition-duration: 2s;

    fill: #181D20;  /* To use it, you have to remove the property form the svg file */
}

.alpha {

    stroke: crimson; /* To use it, you have to remove the property form the svg file */
}

.bravo {

    stroke: royalblue; /* To use it, you have to remove the property form the svg file */
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.smooth {
    transition-property: all;
    transition-duration: 3s;
    transition-delay: 0s;
}


/*


.appearing {
    animation-duration: 1s;
    animation-name: appear;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: initial;
    }
}



.disappearing {
    animation-duration: 1s;
    animation-name: disappear;
}

@keyframes disappear {
    from {
        opacity: initial;
    }

    to {
        opacity: 0;
    }
}


.pulsing {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

.pulsing-fast {
    animation: pulse 0.1s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

*/
