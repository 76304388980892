/*

STYLE USED BY THE VECTORIAL ANIMATIONS OF THE WILD-ANIMATION LIBRARY

*/



.operator {

    transition-property: all;
    transition-duration: 2s;

    fill: #181D20;  /* To use it, you have to remove the property form the svg file */
}

.alpha {

    stroke: crimson; /* To use it, you have to remove the property form the svg file */
}

.bravo {

    stroke: royalblue; /* To use it, you have to remove the property form the svg file */
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.smooth {
    transition-property: all;
    transition-duration: 3s;
    transition-delay: 0s;
}
