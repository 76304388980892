:root {
    /* Spacings: Usable for margins, paddings, gaps */
    --spacing-xsmall: 1px;
    --spacing-small: 2px;
    --spacing-medium: 10px;
    --spacing-large: 20px;
    --spacing-xlarge: 40px;
    --spacing-xxlarge: 100px;

    /* Sizes: Usable for bars and buttons */
    --size-small: 20px;
    --size-medium: 40px;
    --size-large: 60px;
    --size-xlarge: 80px;
    --size-xxlarge: 100px;
    --size-xxxlarge: 140px;

    /* Radius: Usable for any elements  */
    --radius-xsmall: 2px;
    --radius-small: 4px;
    --radius-medium: 8px;
    --radius-large: 14px;
    --radius-xlarge: 20px;
    --radius-max: 50%;

    /* Borders usable for any elements  */
    --border-small: 1px;
    --border-medium: 2px;
    --border-large: 3px;

    /* Regular couples of colors usable for notifications */
    --color-background-success: #3CB371;
    --color-text-success: #222222;
    --color-background-warning: #FFA500;
    --color-text-warning: #222222;
    --color-background-error: #DC143C;
    --color-text-error: #FFFFFF;
    --color-background-information: #FFFFFF;
    --color-text-information: #222222;
    --color-background-action: #FFFFFF;
    --color-text-action: #222222;
    --color-background-cancel: #222222;
    --color-text-cancel: #FFFFFF;

    /* Responsive breakpoints */

}

body {

}

body.darkTheme {

}
