.desktop-only {
    display: initial;
}

.mobile-only {
    display: none !important;
}

@media (max-width: 800px) {
    .desktop-only,
    .optional {
        display: none !important;
    }

    .mobile-only {
        display: initial !important;
    }
}
