html,
body {
    margin: 0;
    height: 100%;
    overflow-y: auto;
    /* user-select: none; */
    font-family: Arial, Helvetica, sans-serif;
    background-color: var(--color-background-2);
    color: var(--color-text-0);

    /* disable blue-highlighting on tap on mobile */
    -webkit-tap-highlight-color: transparent;
}

::selection {
    background-color: var(--color-background-1);
    color: var(--color-text-3);
}

select {
    background-color: var(--color-background-1);
    color: var(--color-text-0);
    font-size: medium;
    border: none;
}

select:hover {
    border-color: white;
}

select:focus {
    border: none;
    outline: none;
}

select option {
    text-align-last: right;
}

a,
a:hover,
a:active,
a:visited {
    color: var(--color-text-1);
    text-decoration: none;
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
