@import url('./styles-variables.scss');
@import url('./styles-overrides.scss');
@import url('./styles-breakpoints.scss');

@import url('./styles-vectorial-animations.scss');

.valid {
    color: var(--color-valid) !important;
}

.invalid,
.error {
    color: var(--color-error) !important;
}

.warning {
    color: var(--color-warning) !important;
}

.highlighted {
    background-color: rgba(#444444, 0.8);
}

.store {
    background-color: var(--color-background-6) !important;
}

@media (max-width: 800px) {

    .optional {

        display: none !important;
    }

}
